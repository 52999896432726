
import '@lahaus-web-components/lhwc-documents/dist/index.css';

import { 
  ContainerDocumentsListing
} from '@lahaus-web-components/lhwc-documents'

function App() {
  
  return (
    <div className="App">
      <ContainerDocumentsListing 
          cdp_user_id=""
          pcp_project_code=""
          pcp_unit_detail_code=""
          checkout_reserve_uuid=""
          checkout_reserve_id=""
          source="" 
          api-key=""
      />
    </div>
  );
}

export default App;
